import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectOptionsState } from '../reducers/select-options.reducers';
import { SELECT_OPTIONS_KEY } from '../keys';

export const selectState = createFeatureSelector<SelectOptionsState>(SELECT_OPTIONS_KEY);
export const selectVehicles = createSelector(selectState, (state) => state.vehicles);
export const selectDrivers = createSelector(selectState, (state) => state.drivers);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectPartners = createSelector(selectState, (state) => state.partners);
export const selectActivePartners = createSelector(selectState, (state) => state.partners.filter((partner) => partner.active === true));
