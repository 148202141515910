import { inject, Injectable } from '@angular/core';
import { filter, map, Observable, take, tap } from 'rxjs';
import { SelectOptionsFacade } from '../facades/select-options.facade';

@Injectable({
    providedIn: 'root',
})
export class DriverOptionsGuard {
    private readonly selectOptionsFacade = inject(SelectOptionsFacade);
    canActivate(): Observable<boolean> {
        return this.selectOptionsFacade.drivers$.pipe(
            tap((drivers) => {
                if (drivers.length === 0) {
                    this.selectOptionsFacade.getDrivers();
                }
            }),
            filter((drivers) => drivers.length !== 0),
            map(() => true),
            take(1),
        );
    }
}
