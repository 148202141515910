import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectVehicles, selectDrivers, selectPartners, selectLoaded, selectLoading, selectActivePartners } from '../selectors/select-options.selectors';
import { SelectOptionsAction } from '../actions/select-options.actions';

@Injectable({
    providedIn: 'root',
})
export class SelectOptionsFacade {
    private store = inject(Store);

    drivers$ = this.store.select(selectDrivers);
    vehicles$ = this.store.select(selectVehicles);
    partners$ = this.store.select(selectPartners);
    activePartners$ = this.store.select(selectActivePartners);
    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    getDrivers(active?: boolean): void {
        this.store.dispatch(SelectOptionsAction.getDrivers({ active }));
    }

    getVehicles(active?: boolean): void {
        this.store.dispatch(SelectOptionsAction.getVehicles({ active }));
    }

    getPartners(active?: boolean): void {
        this.store.dispatch(SelectOptionsAction.getPartners({ active }));
    }

    clear(): void {
        this.store.dispatch(SelectOptionsAction.clear());
    }
}
