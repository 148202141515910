import { DriverOptionsDto } from './driver.dto';
import { VehicleOptionsDto } from './vehicle.dto';
import { PartnerOptionsDto } from './partner.dto';

export interface Option {
    label: string;
    value: string;
    active?: boolean;
}

export class PartnerOptions implements Option {
    label: string;
    value: string;
    active: boolean;
    constructor(dto: PartnerOptionsDto) {
        this.label = dto.name;
        this.value = dto.id;
        this.active = dto.active;
    }
}

export class VehicleOptions implements Option {
    label: string;
    value: string;
    active: boolean;
    constructor(dto: VehicleOptionsDto) {
        this.label = dto.name;
        this.value = dto.id;
        this.active = dto.active;
    }
}

export class DriverOptions implements Option {
    label: string;
    value: string;
    constructor(dto: DriverOptionsDto) {
        this.label = dto.name;
        this.value = dto.id;
    }
}
