import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DriverOptions, PartnerOptions, VehicleOptions } from '../models';

export const SelectOptionsAction = createActionGroup({
    source: 'Select Options',
    events: {
        'Get Vehicles': props<{ active?: boolean }>(),
        'Get Vehicles Success': props<{ items: VehicleOptions[] }>(),
        'Get Vehicles Error': emptyProps(),
        'Get Drivers': props<{ active?: boolean }>(),
        'Get Drivers Success': props<{ items: DriverOptions[] }>(),
        'Get Drivers Error': emptyProps(),
        'Get Partners': props<{ active?: boolean }>(),
        'Get Partners Success': props<{ items: PartnerOptions[] }>(),
        'Get Partners Error': emptyProps(),

        Clear: emptyProps(),
    },
});
